// import crypto from "crypto";
import axios from 'axios';
import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';
// import { v1 as uuidv1 } from "uuid";
import { fetch } from './api';

export const CONSUMER_KEY = 'ck_acbf241bb65c7f862c029ab13600b7478279f451';
export const CONSUMER_SECRET = 'cs_9e813872343852a374a8f46158738f090b497683';

// const pathToServe =
//   process.env.SERVE_PATH === "" ? "/" : process.env.SERVE_PATH;

const baseUrl = 'https://wp.klimarechner.ch/wp-json/';
const secretKey = 'cs_1c961a10c5ce4f51e5a6ecd0c28ad0daec1dad44';
const consumerPass = 'ck_dfa7b8b7a52bfee11c4bdc25c545d17617c0962f';

// const method = "POST";

export const getAll = () => {
  return new Promise((resolve, reject) => {
    fetch
      .get(
        `wp-json/wc/v3/products?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`
      )
      .then(response => {
        return resolve(response);
      })
      .catch(error => {
        // console.log(error);
        reject(error.errors);
      });
  });
};

export const getContentById = id => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`wp-json/wp/v2/klimarechner_texte/${id}`)
      .then(response => {
        // console.log("response", response);
        return resolve(response);
      })
      .catch(error => {
        // console.log(error);
        reject(error.errors);
      });
  });
};

export const createOrder = data => {
  return new Promise((resolve, reject) => {
    fetch
      .post(
        `wp-json/wc/v3/orders?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`,
        data
      )
      .then(response => {
        // console.log(data);
        return resolve(response);
      })
      .catch(error => {
        // console.log(error);
        reject(error.errors);
      });
  });
};

export const getRoomTypes = data => {
  return new Promise((resolve, reject) => {
    fetch
      .get(
        `wp-json/wc/v3/products/categories?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`,
        data
      )
      .then(response => {
        // console.log(response);
        return resolve(response);
      })
      .catch(error => {
        // console.log(error);
        reject(error.errors);
      });
  });
};

export const getTextElements = data => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`wp-json/wp/v2/klimarechner_texte`, data)
      .then(response => {
        // console.log(data);
        return resolve(response);
      })
      .catch(error => {
        // console.log(error);
        reject(error.errors);
      });
  });
};

export function saveContactForm(data) {
  return new Promise((resolve, reject) => {
    fetch
      .post(`wp-json/gf/v2/forms/2/submissions`, {
        form_id: 2,
        ...data
      })
      .then(
        res => {
          return resolve(res);
        },
        err => {
          reject(err);
        }
      );
  });
}

export function saveOrders(data) {
  const config = {
    method: 'post',
    url: `https://wp.klimarechner.ch/wp-json/wc/v3/orders?consumer_key=ck_acbf241bb65c7f862c029ab13600b7478279f451&consumer_secret=cs_9e813872343852a374a8f46158738f090b497683`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  };
  axios(config)
    .then(() => {})
    .catch(() => {});
}

export function saveFinalDetails(data) {
  // const oauthTimestamp = Math.floor(Date.now() / 1000);
  // const oauthNonce = uuidv1();
  // const parameters = {
  //   oauth_consumer_key: "ck_dfa7b8b7a52bfee11c4bdc25c545d17617c0962f",
  //   oauth_signature_method: "HMAC-SHA1",
  //   oauth_timestamp: oauthTimestamp,
  //   oauth_nonce: oauthNonce,
  //   oauth_version: "1.0",
  // };
  // const ordered = {};
  // Object.keys(parameters)
  //   .sort()
  //   .forEach((key) => {
  //     ordered[key] = parameters[key];
  //   });
  // let encodedParameters = "";
  // // eslint-disable-next-line
  // for (const [key, value] of Object.entries(ordered)) {
  //   const encodedValue = escape(value);
  //   const encodedKey = encodeURIComponent(key);
  //   if (encodedParameters === "") {
  //     encodedParameters += `${encodedKey}=${encodedValue}`;
  //   } else {
  //     encodedParameters += `&${encodedKey}=${encodedValue}`;
  //   }
  // }
  // const encodedUrl = encodeURIComponent(baseUrl);
  // encodedParameters = encodeURIComponent(encodedParameters);
  // const signatureBaseString = `${method}&${encodedUrl}&${encodedParameters}`;
  // const signingKey = `${secretKey}&`;
  // const oauthSignature = crypto
  //   .createHmac("sha1", signingKey)
  //   .update(signatureBaseString)
  //   .digest()
  //   .toString("base64");
  // const encodedOauthSignature = encodeURIComponent(oauthSignature);
  // console.log("www", encodedOauthSignature);
  // const config = {
  //   method: "post",
  //   url: `https://test.klimarechner.ch/wp-json/gf/v2/entries?oauth_consumer_key=ck_dfa7b8b7a52bfee11c4bdc25c545d17617c0962f&oauth_signature_method=HMAC-SHA1&oauth_timestamp=${oauthTimestamp}&oauth_nonce=${oauthNonce}&oauth_version=1.0&oauth_signature=${encodedOauthSignature}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   data,
  // };

  // axios(config)
  //   .then((response) => {
  //     console.log("response", response.data);
  //   })
  //   .catch((error) => {
  //     console.log("zzz", error);
  //   });

  // working on localhost

  return new Promise((resolve, reject) => {
    fetch
      .post(`wp-json/gf/v2/forms/2/submissions`, {
        form_id: 2,
        ...data
      })
      .then(
        res => {
          return resolve(res);
        },
        err => {
          console.log('ddd', err);
          reject(err);
        }
      );
  });

  // const config = {
  //   method: "post",
  //   url: `https://wp.klimarechner.ch/wp-json/gf/v2/forms/2/submissions?oauth_consumer_key=ck_dfa7b8b7a52bfee11c4bdc25c545d17617c0962f&oauth_signature_method=HMAC-SHA1&oauth_timestamp=${oauthTimestamp}&oauth_nonce=${oauthNonce}&oauth_version=1.0&oauth_signature=${encodedOauthSignature}`,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   data,
  // };
  // return new Promise((resolve, reject) => {
  //   axios(config)
  //     .then((res) => {
  //       console.log(res);
  //       resolve(true);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       reject(e);
  //     });
  // });
}

// testing purposes
export function testing(width, height, depth) {
  let total = Math.floor(width * height * depth);
  total *= 25;
  return total;
}
